:root {
    --black: #000000;
    --white: #fff;
    --dark-grey: #212529;
    --lightgrey: rgb(167, 167, 167);
    --white: white;
    --medgrey:  rgb(95, 95, 95);
    --logoOrange: #f26b3a;
    --logoBlue: #266cb0;
    --extralightgrey: #D3D3D3;
    --silk: #fdf5e6;
  }

.body{
    background-color: var(--dark-grey) !important;
    /* background-size: 40px 40px;
  background-image:
    linear-gradient(to right, grey 1px, transparent 1px),
    linear-gradient(to bottom, grey 1px, transparent 1px); */
    height: 100vh;
    width:100vw;
    scroll-behavior: smooth;
}

.test {
  color: var(--silk);
  font-size: 50px;
}

