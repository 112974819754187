:root {
    --black: #000000;
    --white: #fff;
    --dark-med-grey: #2C2E31;
    --dark-grey: #212529;
    --lightgrey: rgb(167, 167, 167);
    --white: white;
    --medgrey:  rgb(95, 95, 95);
    --logoOrange: #f26b3a;
    --logoBlue: #266cb0;
    --extralightgrey: #D3D3D3;
    --silk: #fdf5e6;
    --hotpink: #ff32ff;
  }

.aboutBody{
  position: relative;
    height: 1000px;
    width: 100vw;
/* left: 50%; 
transform: translate(-50%); */
background-color: var(--dark-grey) !important;
display: flex;
justify-content: center;
}

.row{
  justify-content: center;
}

.homeBox{
  height:100vh;
  /* width:1100px; */
  position: absolute;
  background-color: var(--dark-grey);

  /* box-shadow: 10px 5px 5px var(--lightgrey); */
}


.homePhoto{
    filter: grayscale(70%);
    opacity: 99%;
    position: absolute;
    width: auto;
    height: 800px;
    z-index: 7;
    left:0px;

  top:-150px;
    /* bottom: 0px !important; */
  
  
}

.pixelPhoto{
  filter: grayscale(70%);
  opacity: 99%;
  position: absolute;
  width: auto;
  height: 700px;
  z-index: 3;
  left:80px;

top:-70px;
  /* bottom: 0px !important; */


}

/* .pixelPhoto{
  filter: grayscale(70%);
  opacity: 99%;
  position: absolute;
  width: auto;
  height: 50%;
  max-height: 800px;
  right: 30px;
  z-index: 9;
  bottom: 0px !important;


} */

.aboutTitle{
  /* z-index: ; */
  height: 150px;
  width: 100vw;
  display:flex;
  justify-content: center;
  margin-bottom: 20px;

}

.aboutTitleText{
  margin-top: -50px;
  font-size: 110px;
  color: var(--dark-grey);

  /* color: #743ad5; */
/* background-image:  linear-gradient(to left, #743ad5, #d53a9d); */
  margin-left: 100px;
  z-index: 10;
  height: 150px;
  text-align: right;
  /* Set the background size and repeat properties. */
  /* background-size: 100%;
  background-repeat: repeat; */
  /* Use the text as a mask for the background. */
  /* This will show the gradient as a text color rather than element bg. */
  /* -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;  */
}

.aboutTitleBox{
  background-image: linear-gradient(to left, #743ad5, #d53a9d);
  position: absolute;
  height: 30px;
  width: 160px;
  margin-left:100px;
  top: 93px;
  z-index: 8;
  border-radius: 1px;
  
}

.aboutLeft{
  width:600px;
  height: 550px;
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: ease-in 3ms;
}

.aboutRight{
  width: 550px;
  height:600px;
  margin-top: -50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .aboutTextBox{
  width:300px;
  position: relative;
  height: 180px;
  margin-left: 650px;
  top:180px;
  border-radius: 3px 3px 20px 3px;
  box-shadow: 7px 5px 7px var(--black);
} */
/* .aboutSectionBorder{
  border: thin solid;
  border-color: var(--silk);
  border-radius: 5px;
  z-index: 999;
} */

.aboutSectionBorder{
  top: 100px;
  position:absolute;
  width: 100vw;
  z-index: 8;
  height: 650px;
  /* left: 420px;
  top: 250px; */
  /* border: 10px solid; */
  /* border-image-slice: 1; */
  /* border-width: 15px; */
  background-image:  linear-gradient(to left, #743ad5, #d53a9d);
  /* border-image-source: linear-gradient(to left, #743ad5, #d53a9d); */
  /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
  display: flex;
  justify-content: center;
  
  
}

.aboutSection{
  position:absolute;
  width: 390px;
  /* border: thick solid;
  border-color: var(--silk);
  border-radius: 5px; */
  z-index: 11;
  height: 250px;
  left: 400px;
  top: 200px;
  /* background-image: linear-gradient(to left, #743ad5, #d53a9d); */
  background-color: var(--dark-grey);

  /* border-right: 10px solid;
  border-bottom: 10px solid; */
  border-image-slice: 1;
  border-width: 5px;

  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

  /* border-image-source: linear-gradient(to left, #743ad5, #d53a9d); */
  border: 10px solid;
  border-image-slice: 1;
  border-width: 2px;
  /* background-image:  linear-gradient(to left, #743ad5, #d53a9d); */
  border-image-source: linear-gradient(to left, #743ad5, #d53a9d);
}

.aboutText{
  color: var(--silk);
  padding: 25px;
  font-size: 21px;
  font-weight: 400;
  z-index: 11;
  text-align: justify;
  height: 200px;
  

}

/* .techAboutSectionBorder{
  z-index: 10;
  position:absolute;
  width: 400px;
  border: thick solid;
  border-color: var(--silk);
  border-radius: 5px;
  z-index: 8;
  top: 200px;
  height: 500px;
  right: 250px;
} */

.techAboutSection{
  z-index: 10;
  position:absolute;
  /* width: 600px; */
  /* border: 10px solid;
  border-image-slice: 1;
  border-width: 5px;
  border-image-source: linear-gradient(to left, #743ad5, #d53a9d); */
  /* border-color: var(--hotpink); */
  border-radius: 400px;
  z-index: 8;
  height: 500px;
  /* right: 50px;
  top:200px; */

}

.techAboutText{
  color: var(--silk);
  padding: 60px 30px 60px 30px;

  font-size: 21px;
  /* background-color: var(--dark-grey); */
  left: 320px;
  font-weight: 400;
  z-index: 15;
  text-align: center;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  justify-items: center;
}

.iconTile{
  /* background-image: linear-gradient(to left, #743ad5, #d53a9d); */
  background-color: var(--dark-grey);
  width: 150px;
  height:90px;
  margin: 10px;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  /* transition: all 1s ease; */
  border: 10px solid;
  border-image-slice: 1;
  border-width: 2px;
  /* background-image:  linear-gradient(to left, #743ad5, #d53a9d); */
  border-image-source: linear-gradient(to left, #743ad5, #d53a9d);
  font-size: 15px;
}

.icon{
  height:50px;
  width:50px;
  padding: 5px 5px 5px 5px;
  margin-top: 5px;
}

.icon6{
  height:50px;
  width:60px;
  padding: 20px 10px 0px 10px;
  margin-top: 0px;
}

/* .iconTile:hover{
  transform: scale(1.25);
} */




/* .aboutBar{
  background-color: #ff32ff;
  color: var(--silk);
  font-weight: bold;
  padding-left: 10px;
} */

/* .aboutParagraph{
  color:var(--silk);
  position:fixed;
  width: 800px;
  left: 108px;
  top: 520px;
  font-size: 50px;
  font-weight: 100;
  z-index: 999;
} */

/* .nameHomeBox{

    left: 200px;
    position: relative;
    top:170px;
    height: auto;
    z-index: 99;
    margin-top: 10%;
    width: 400px;
  
    
} */

/* .nameHome2{
  color: #ff32ff;
  font-size: 150px;
  font-weight: 100;
  margin-top: -75px;
  margin-bottom: 0px;
  text-align: left;
  animation: fadein .5s;
  width: 900px;

} */
/* .nameHome{
    color: var(--silk);
    font-size: 150px;
    font-weight: 100;
    margin-top: -75px;
    margin-bottom: 0px;
    text-align: left;
    animation: fadein .5s;

} */

@keyframes fadein {
  from {
      opacity:0;
  }
  to {
      opacity:1;
  }
}

  .appSection {
    background-image: linear-gradient(to left, #2E1755, #743ad5);
    width:100vw;
    height: 400px;
  }
  .footerSection {
    background-color: var(--dark-grey);
    width:100vw;
    height: 200px;
  }

.contactSection{
  background-color: var(--dark-med-grey);
  /* background-image: linear-gradient(to left, #2E1755, #743ad5); */
  width:100vw;
  height: 350px;
  display: flex;
  justify-content: center;
}

.contactArea{
text-align: center;
}

.contactText{
  padding-top: 110px;
  font-size: 38px;
  color: var(--silk);


}

.contactBtn{
  height:60px;
  width:190px;
  margin:15px;
  background-color: var(--dark-med-grey);
  border:solid 3px;
  border-color: #d53a9d;
  color: var(--silk);
  font-size: 23px;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;

}

.contactBtn:hover{
  background-color: #d53a9d;
  transition: all ease .25s;
  color:var(--silk);
  border:solid 3px;
  border-color: #d53a9d;
}

.contactBtnLine:hover{
  height: 40px;
  animation-name: cover;

} 

.footerArea{
  display: flex;
  justify-content: center;
  align-content: center;
  padding:25px;
}

.contactBtnArea1{
  display: flex;
  justify-content: center;
  align-content: center;
}

.contactIcon{
  height: 80px;
  width: 80px;
  display: flex;
  justify-content: center;
}




.fa{
  font-size: 35px !important;
  /* margin:50px 30px 5px 30px; */
  color: var(--silk);
  /* background-image:  linear-gradient(to left, #743ad5, #d53a9d); */
  padding: 15px;
  height: 60px;
  width: 60px;
  border-radius: 50px;

}

.upIcon1{

  height: 30px;
  width: 20px;
  /* background-image:  linear-gradient(to left, #743ad5, #d53a9d);  */
  color: var(--silk)!important;
  font-size: 20px !important;
  z-index: 10;
  padding-left:0px;
}

.upIcon2{
  top:10px;
  height: 20px;
  width: 30px;
  /* background-image:  linear-gradient(to left, #743ad5, #d53a9d);  */
  color: var(--silk)!important;
  font-size: 20px !important;
  z-index: 10;
}

.iconText{
font-size: 20px;
}

.backToTopSection{
width:100vw;
height:50px;
display: flex;
justify-content: center;
}

.backToTop{
  position: absolute;
  margin-top: -22px;
  height: 60px;
  width: 40px;
  /* background-color:#d53a9d; */
    background-image:  linear-gradient(to left, #743ad5, #d53a9d); 

  color:#fff;
  display:flex;
  justify-content: center;
  box-shadow: 4px 2px 2px #000000;
}

@media screen and (max-width: 1399px) {
  .aboutBody{
    height: 850px;
  
  }

  .row{
    width: 1150px;
  }

  .homePhoto{
    left:-90px;
    height: 770px;
  }

  .aboutLeft{
    width:450px;
    height: 550px;
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .aboutSectionBorder {
    top: 100px;
    position:absolute;
    width: 1200px;
    z-index: 8;
    height: 650px;
    border: 10px solid;
    border-image-slice: 1;
    border-width: 15px;
    border-image-source: linear-gradient(to left, #743ad5, #d53a9d);
    display: flex;
    /* background-color: var(--dark-grey); */
    background-image: var(--dark-grey);
    justify-content: center;
    
  }

  .aboutSection{
    left:150px;
      background-image: linear-gradient(to left, #743ad5, #d53a9d);

  }

  .container{
    width: 1200px !important;
  }
  .aboutTitleText{
    margin-top: -50px;
    font-size: 106px;
    color: var(--dark-grey);
  
    /* color: #743ad5; */
  background-image:  linear-gradient(to left, #743ad5, #d53a9d);
    margin-left: 35px;
    z-index: 10;
    height: 150px;
    text-align: right;
    /* Set the background size and repeat properties. */
    background-size: 100%;
    background-repeat: repeat;
    /* Use the text as a mask for the background. */
    /* This will show the gradient as a text color rather than element bg. */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; 
  }

}


@media screen and (max-width: 1200px) {
  .aboutBody{
    height: 1380px;
  
  }
  .homePhoto{
    filter: grayscale(70%);
    opacity: 99%;
    position: absolute;
    width: auto;
    height:1100px;
    z-index: 7;
    left:-120px;

  top:-80px;
    /* bottom: 0px !important; */
  
  
}

.containter{
width: 800px !important;
}



  .aboutSectionBorder{
    top: 100px;
    position:absolute;
    width: 800px;
    z-index: 8;
    height: 1050px;
    border: 10px solid;
    border-image-slice: 1;
    border-width: 15px;
    border-image-source: linear-gradient(to left, #743ad5, #d53a9d);
    display: flex;
    justify-content: center;
    
  }

  .aboutTitleText{
    margin-top: -37px;
    font-size: 85px;
    color: #743ad5;
  background-image:  linear-gradient(to left, #743ad5, #d53a9d);
    margin-left: 230px;
    z-index: 9;
    height: 150px;
    text-align: right;
    /* Set the background size and repeat properties. */
    background-size: 100%;
    background-repeat: repeat;
    display: flex;
    justify-content: start;
    /* Use the text as a mask for the background. */
    /* This will show the gradient as a text color rather than element bg. */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; 
  }
  

  .aboutSection{
    position:absolute;
    width: 390px;
    z-index: 11;
    height: 250px;
    left: 395px;
    top: 200px;
    background-image: linear-gradient(to left, #743ad5, #d53a9d);
    border-image-slice: 1;
    border-width: 5px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;  
  }
  .container{
    width: 800px;
  }
  .aboutRight{
    width: 550px;
    height:600px;
    margin-top: 350px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: -670px !important;
  }

}

@media screen and (max-width: 995px) {
  .aboutTitleText{
    margin-top: -6px;
    margin-right: 465px;
    font-size: 100px;
    color: #743ad5;
    line-height: 20mm;
    background-image:  linear-gradient(to left, #743ad5, #d53a9d);
    z-index: 9;
    height: 550px;
    text-align: right;
    background-size: 100%;
    background-repeat: repeat;
    /* -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;  */
    width: 600px;
  }
  .techAboutSection{
    z-index: 10;
    position:absolute;
    border-radius: 400px;
    z-index: 8;
    height: 500px;
    /* right: 50px;
    top:200px; */
  }
    .aboutRight{
      width: 450px;
      height:600px;
      margin-top: -75px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: -1300px !important;
  
  }
}


/* 400px  */


@media screen and (max-width: 850px) {
  .aboutBody{
    height: 1200px;
  
  }

  .row{
    width:750px;
  }
  .aboutSectionBorder{
    top: 100px;
    position:absolute;
    width: 600px;
    z-index: 8;
    border: 5px solid;
    border-image-slice: 1;
    border-width: 15px;
    border-image-source: linear-gradient(to left, #743ad5, #d53a9d);
    display: flex;
    justify-content: center;
    height: 650px;
    
  }

.homePhoto{
  filter: grayscale(70%);
  opacity: 99%;
  position: absolute;
  width: auto;
  height: 670px;
  z-index: 7;
  left:-70px;
  top:-50px;
  /* bottom: 0px !important; */


}

.aboutSection{
  width: 300px;
  height: 200px;
  left: 250px;
  top: 60px;
background-color: transparent;
background-image: none;
  /* border-image-slice: 1; */
  border-width: 0px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 0px 0px;

}

.aboutText{
  color: var(--silk);
  padding: 30px;
  font-size: 21px;
  font-weight: 400;
  z-index: 11;
  text-align: justify;
  height: 200px;
}

.aboutTitleText{
  margin-top: 484px;
  margin-right: 218px;
  font-size: 90px;
  color: #743ad5;
  line-height: 18mm;
  background-image:  linear-gradient(to left, #743ad5, #d53a9d);
  z-index: 9;
  height: 250px;
  text-align: right;
  background-size: 100%;
  background-repeat: repeat;
  /* -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;  */
  width: 550px;
}

.techAboutSection{
  z-index: 10;
  position:absolute;
  border-radius: 400px;
  z-index: 8;
  height: 500px;
  /* right: 50px;
  top:200px; */

}

.techAboutText{
  color: var(--silk);
  padding: 60px 30px 60px 30px;

  font-size: 21px;
  /* background-color: var(--dark-grey); */
  left: 320px;
  font-weight: 400;
  z-index: 15;
  text-align: center;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  justify-items: center;
}

.iconTile{
  background-image: linear-gradient(to left, #743ad5, #d53a9d);
  /* background-color: var(--dark-grey); */
  width: 100px;
  height:90px;
  margin: 10px;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  /* transition: all 1s ease; */
  border: 10px solid;
  border-image-slice: 1;
  border-width: 2px;
  /* background-image:  linear-gradient(to left, #743ad5, #d53a9d); */
  border-image-source: linear-gradient(to left, #743ad5, #d53a9d);

}


.aboutLeft{
  width:600px;
  height: 120px;
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.aboutRight{
  width: 450px;
  height:600px;
  margin-top: -290px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto !important;
  margin-right: auto !important;
}


}

@media screen and (max-width: 767px) {
  .aboutTitleText{
    margin-right: 400px;

  }
  
}

@media screen and (max-width: 450px) {
  .aboutBody{
    height: 1200px;
  
  }

  .row{
    width:450px;
  }
  .aboutSectionBorder{
    top: 100px;
    position:absolute;
    width: 350px;
    z-index: 8;
    border: 5px solid;
    border-image-slice: 1;
    border-width: 15px;
    border-image-source: linear-gradient(to left, #743ad5, #d53a9d);
    display: flex;
    justify-content: center;
    height: 650px;
    
  }

.homePhoto{
  filter: grayscale(70%);
  opacity: 99%;
  position: absolute;
  width: auto;
  height: 620px;
  z-index: 7;
  left:-70px;
  top:0px;
  /* bottom: 0px !important; */


}

.aboutSection{
  width: 300px;
  height: 200px;
  left: 34px;
  top: 370px;
  background-image: linear-gradient(to left, #743ad5, #d53a9d);
  border-image-slice: 1;
  border-width: 5px;
  /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */

}

.aboutText{
  color: var(--silk);
  padding: 30px;
  font-size: 15px;
  font-weight: 400;
  z-index: 11;
  text-align: justify;
  height: 200px;
}

.aboutTitleText{
  margin-top: 298px;
  margin-right: 212px;
  font-size: 50px;
  color: #743ad5;
  line-height: 9.75mm;
  background-image:  linear-gradient(to left, #743ad5, #d53a9d);
  z-index: 9;
  height: 150px;
  text-align: right;
  background-size: 100%;
  background-repeat: repeat;
  /* -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;  */
  width: 300px;
}

.techAboutSection{
  z-index: 10;
  position:absolute;
  border-radius: 400px;
  z-index: 8;
  height: 500px;
  /* right: 50px;
  top:200px; */

}

.techAboutText{
  color: var(--silk);
  padding: 60px 30px 60px 30px;

  font-size: 21px;
  /* background-color: var(--dark-grey); */
  left: 320px;
  font-weight: 400;
  z-index: 15;
  text-align: center;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  justify-items: center;
}

.iconTile{
  background-image: linear-gradient(to left, #743ad5, #d53a9d);
  /* background-color: var(--dark-grey); */
  width: 100px;
  height:90px;
  margin: 10px;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  /* transition: all 1s ease; */
  border: 10px solid;
  border-image-slice: 1;
  border-width: 2px;
  /* background-image:  linear-gradient(to left, #743ad5, #d53a9d); */
  border-image-source: linear-gradient(to left, #743ad5, #d53a9d);

}


.aboutLeft{
  width:600px;
  height: 120px;
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.aboutRight{
  width: 450px;
  height:600px;
  margin-top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0px !important;
}

.contactText{
  padding-top: 120px;
  font-size: 22px;
  color: var(--silk);


}

}

