:root {
    --black: #000000;
    --white: #fff;
    --dark-grey: #212529;
    --lightgrey: rgb(167, 167, 167);
    --white: white;
    --medgrey:  rgb(95, 95, 95);
    --logoOrange: #f26b3a;
    --logoBlue: #266cb0;
    --extralightgrey: #D3D3D3;
    --silk: #fdf5e6;
  }
  
  /* .circle-leave-active { animation: 1s circle; } */
  
.websiteBody{
  background-color: var(--dark-grey) !important;

}

.iconText{
  margin: 0px;
}

.landingBody{
  position:relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--dark-grey) !important;
  height: 100vh;
  width:100vw;
}

  .landingContainer{
    /* position:relative; */
    display: flex;
    justify-content: center;
    align-items: center;
    width:450px;
    height:450px;
    border-radius: 250px;
    z-index: 13;

    /* width:100vw;
    height: 100vh; */
      /* background-image: linear-gradient(to right top, #f0f, #ff32ff,#ffccff, #ffffff);   */
      background-image: linear-gradient(to left, #743ad5, #d53a9d);
      /* clip-path: circle(95%);
      animation: 3s circle;
      animation-fill-mode: forwards; 
      filter: drop-shadow(0 0 4rem crimson);
      z-index: 999;
      transition: clip-path 3s; */

  }

  /* .landingContainer:hover {
    clip-path: circle(150px);

  } */

  /* @keyframes circle {
    0% { clip-path: circle(75%); }
    100% { clip-path: circle(200px); }
  } */
  
  @keyframes float {
      0% {
          transform: translatey(0px);
      }
      50% {
          transform: translatey(-8px);
      }
      100% {
          transform: translatey(0px);
      }
  }
  
  .scrollPrompt{
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  font-weight: 600;
  font-variant: all-petite-caps;
  text-align: center; 
  }
  
  .arrowText{
    margin-top: 10px;
    font-size: 20px;
    font-weight: 600;
    color: var(--medgrey);
    animation: float 3s ease-in-out infinite;
    text-decoration: none;
  }
  
  
  /* .landingText{
    text-decoration: none;
    color: var(--dark-grey);
    font-weight: bold;
    font-size: 20px;
    letter-spacing: 10px;
  } */
  
.landingText{
    font-size: 25px;
    /* background-color: var(--dark-grey); */
    letter-spacing: 10px;
    text-decoration: none !important;
    font-weight: bold;
    max-width: 100vw;
    color: var(--dark-grey);
    /* clip-path: circle(200%);
    transition: clip-path 1s; */
    text-align: center;
    padding: 0 0px 0 10px;
    transition: all .75s ease-in-out; 
    
    
  }


.landingText:hover{
    color: var(--white);
    letter-spacing: 4px;
 }

 .landingText:active{
  color: var(--lightgrey);
  letter-spacing: 4px;
}
  
  
  
  
  .headerIcons{
    display: flex;
    justify-content: center;
    align-items: center;
  
  }
  
  
  .App {
    text-align: center;
  }
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  .testsection{
    background-color: var(--medgrey);
  }
  
  
.bubble{

  position: relative;
  z-index: 7;
  width: 0;
  height: 0;
  margin-top: -50px;
  margin-left: 500px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 10px solid #212529;
}


.bubbleLanding{

  position: absolute;
  z-index: 7;
  /* margin-top: -600px; */
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100vw;
  height: 500px;
  margin-left: auto;
  margin-right: auto;

}

.shift1{
  /* animation-duration: 45s; */
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-name: shift-1;
  animation-timing-function: ease;
}

.shift2{
  animation-duration: 20s;
  animation-iteration-count: infinite;
  animation-name: shift-2;
  animation-timing-function: ease;

}

.shift3{
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-name: shift-3;
  animation-timing-function: ease;

}

.shift4{
  animation-duration: 20s;
  animation-iteration-count: infinite;
  animation-name: shift-4;
  animation-timing-function: ease;

}



.bubble1{
  margin-top: -50px;
  margin-left: 50px;
  border-bottom: 10px solid #212529;
  z-index: 7;


}

.bubble2{
  margin-top: 40px;
  margin-left: 0px;
  border-bottom: 10px solid rgb(95,95,95);
  z-index: 7;
  animation-delay: 3000ms;

}

.bubble3{
  margin-top: 75px;
  margin-left: 15px;
  border-bottom: 10px solid #d3d3d3;
  z-index: 7;
  animation-delay: 6000ms;
}

.bubble4{
  margin-top: 100px;
  margin-left: 7px;
  border-bottom:  #743ad5;
  z-index: 7;
  animation-delay: 200ms;
}

.bubble5{
  margin-top: 75px;
  margin-left: 20px;
  border-bottom: 10px solid rgb(95,95,95);
  z-index:11;
  animation-delay: 1000ms;

}

.bubble6{
  margin-top: 160px;
  margin-left: 10px;
  border-bottom: 10px solid #d3d3d3;
  z-index: 7;
  animation-delay: 700ms;
}

.bubble7{
  margin-top: 90px;
  margin-left: 6px;
  border-bottom: 10px solid #d53a9d;
  z-index: 7;
  animation-delay: 4200ms;
}

.bubble8{
  margin-top: -30px;
  margin-left: 63px;
  height:10px;
  width: 10px;
  border-radius: 10px ;
  border-bottom: 10px solid rgb(95,95,95);
  z-index: 7;
  animation-delay: 1110ms;
}

.bubble9{
  margin-top: -50px;
  margin-left: 23px;
  border-bottom: 10px solid #d3d3d3;
  z-index: 11;
  animation-delay: 270ms;
}

.bubble10{
  margin-top: -70px;
  margin-left: 5px;
  border-bottom: 10px solid #d53a9d;
  z-index: 7;
  animation-delay: 4000ms;
}

.bubble11{
  margin-top: 2px;
  margin-left: 15px;
  border-bottom: 10px solid #743ad5;
  z-index: 7;
  animation-delay: 300ms;
}

.bubble12{
  margin-top: -234px;
  margin-left: -30px;
  border-bottom: 10px solid rgb(95,95,95);
  z-index: 7;
  animation-delay: 500ms;

}

.bubble13{
  margin-top: -42px;
  margin-left: 0px;
  border-bottom: 10px solid #ff32ff;
  z-index: 7;
  animation-delay: 530ms;
}

.bubble14{
  margin-top: -20px;
  margin-left: 40px;
  background-color: #212529;
  z-index: 7;
  animation-delay: 50ms;
}

.bubble15{
  margin-top: -80px;
  margin-left: 10px;
  border-bottom: 10px solid rgb(95,95,95);
  z-index: 7;

}

.bubble16{
  margin-top: -160px;
  margin-left: 60px;
  border-bottom: 10px solid #d3d3d3;
  z-index: 11;
  animation-delay: 4000ms;

}

.bubble17{
  margin-top: -190px;
  margin-left: 80px;
  border-bottom: 10px solid #d53a9d;
  z-index: 7;
  animation-delay: 2000ms;

}

.bubble18{
  margin-top: -280px;
  margin-left: 90px;
  border-bottom: 10px solid rgb(95,95,95);
  z-index: 7;
  animation-delay: 400ms;

}

.bubble19{
  margin-top: -150px;
  margin-left: 30px;
  border-bottom: 10px solid #d3d3d3;
  z-index: 7;
  animation-delay: 123ms;

}

@keyframes shift-1 {
  0%   { transform: translateY(0); }
  25%  { transform: translateY(0px) translateX(-100px); }
  50%  { transform: translateY(-100px) translateX(-100px); }
  75%  { transform: translateY(-100px) translateX(0px); }
  100% { transform: translateY(0); }
}

@keyframes shift-2 {
  0%   { transform: translateY(0); }
  25%  { transform: translateY(0px) translateX(100px); }
  50%  { transform: translateY(100px) translateX(100px); }
  75%  { transform: translateY(100px) translateX(0px); }
  100% { transform: translateY(0); }
}

@keyframes shift-3 {
  0%   { transform: translateY(0); }
  20%  { transform: translatex(400px);}
  40% { transform: translateY(0); }
  60%   { transform: translateY(0); }
  80%  { transform: translatex(400px);}
  100% { transform: translateY(0); }
}


@keyframes shift-4 {
  0%   { transform: translateY(0); }
  12.5%  { transform: translateY(100px) translateX(-100px);}
  25%  { transform: translateY(200px) translateX(-200px);}
  37.5%  { transform: translateY(-300px) translateX(-100px);}
  50%  { transform: translateY(-400px) translateX(0px);}
  62.5%  { transform: translateY(-100px) translateX(-100px);}
  75%  { transform: translateY(-200px) translateX(140px);}
  87.5%  { transform: translateY(-100px) translateX(-100px);}
  100% { transform: translateY(0); }
}



@media screen and (max-width: 450px) {
  .landingBody{
    position:relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--dark-grey) !important;
    height: 100vh;
    width:100vw;
  }

    .landingContainer{
    /* position:relative; */
    display: flex;
    justify-content: center;
    align-items: center;
    width:250px;
    height:250px;
    border-radius: 250px;

    /* width:100vw;
    height: 100vh; */
      /* background-image: linear-gradient(to right top, #f0f, #ff32ff,#ffccff, #ffffff);   */
      background-image: linear-gradient(to left, #743ad5, #d53a9d);
      /* clip-path: circle(95%);
      animation: 3s circle;
      animation-fill-mode: forwards; 
      filter: drop-shadow(0 0 4rem crimson);
      z-index: 999;
      transition: clip-path 3s; */

  }
}

