:root {
  --black: #000000;
  --white: #fff;
  --dark-grey: #212529;
  --lightgrey: rgb(167, 167, 167);
  --white: white;
  --medgrey: rgb(95, 95, 95);
  --logoOrange: #f26b3a;
  --logoBlue: #266cb0;
  --extralightgrey: #D3D3D3;
  --silk: #fdf5e6;
}


.workTitle {
  color: var(--silk);
}

.workBody {
  position: relative;
  height: 1500px;
  width: 100vw;
  left: 50%;
  transform: translate(-50%);
  background-color: var(--dark-grey) !important;

}

.workTitle {
  /* z-index: ; */
  height: 150px;
  width: 100vw;
  display: flex;
  justify-content: center;
  margin-bottom: 50px;

}

.workContainer {
  width: 100vw;
  display: flex;
  justify-content: center;
}

.workSection {
  width: 1200px;
  display: flexbox;
  align-items: center;
  justify-content: center;
}

.workTitleText {
  margin-top: 35px;
  font-size: 60px;
  color: var(--silk);
  z-index: 9;
  height: 150px;
  text-align: center;
}

.workTitleBox {
  background-image: linear-gradient(to left, #743ad5, #d53a9d);
  position: absolute;
  height: 30px;
  width: 220px;
  margin-left: 100px;
  top: 90px;
  z-index: 8;
  border-radius: 1px;
}

.projectLeft {
  width: 100vw;
  height: 550px;
  margin-bottom: 80px;
}

.projectLeftSection {
  z-index: 10;
  position: absolute;
  width: 850px;
  z-index: 8;
  height: 400px;
  border: 10px solid;
  border-image-slice: 1;
  border-width: 5px;
  /* background-color: #743ad5 ; */
  /* left:0px; */
  background-image: linear-gradient(to left, #743ad5, #2E1755);
}

.projectPhotoLeft {
  height: 440px;
  width: 550px;
  position: absolute;
  margin-left: 50px;
  margin-top: 55px;
  filter: grayscale(100);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
transition: all 1s ease;
}

.projectPhotoLeft:hover{
  transform: scale(.9);
  filter:grayscale(0)
}

p {
  margin-bottom: 0px;
}

.projectLeftText {
  color: var(--silk);
  padding: 30px;
  /* font-size: 25px; */
  font-weight: 400;
  z-index: 11;
  height: 200px;
  margin-left: 600px;
  width: 550px;
  

}

a, a:hover, a:visited, a:active{
color: inherit;
text-decoration: none;
}

.projectLeftTitle {
  margin-top: 160px;
  font-size: 40px;
  line-height: .9;
  font-size: 50px;
}

.projectLeftDesc {
  margin-top: 30px;
  font-size: 22px;
  width: 400px;
  line-height: .9;
}

.projectLeftLiveBtn {
  margin-top: 50px;
  font-size: 22px;
}

.projectLeftLiveBtnLine {
  width: 92px;
  height: 4px;
  background-color: #d53a9d;
}

.projectLeftLive:hover .projectLeftLiveBtnLine{
  width: 5px;
  transition: all 2s ease;
}


.projectLeftLearnMoreBtn {
  margin-top: 20px;
  font-size: 22px;
}

.projectLeftLearnMoreBtnLine {
  width: 134px;
  height: 4px;
  background-color: #d53a9d;
}

.projectLeftLearnMore:hover .projectLeftLearnMoreBtnLine {
  width: 5px;
  transition: all 2s ease;
}

.projectRight {
  width: 1200px;
  height: 550px;
  margin-bottom: 60px;
  display: flex;
  justify-content: right;
}


.projectRightSection {
  z-index: 10;
  position: absolute;
  width: 850px;
  z-index: 8;
  height: 400px;
  border: 10px solid;
  border-image-slice: 1;
  border-width: 5px;
  /* background-color: #743ad5 ; */
  /* right: 0px; */
  background-image: linear-gradient(to left, #2E1755, #743ad5);
  /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
  transition: all 1s ease;
}

.projectPhotoRight {
  height: 440px;
  width: 550px;
  position: absolute;
  margin-left: 220px;
  margin-top: 55px;
  filter: grayscale(100);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  transition: all 1s ease;
}

.projectPhotoRight:hover{
    transform: scale(.9);
    filter:grayscale(0)
}

p {
  margin-bottom: 0px;
}

.projectRightText {
  color: var(--silk);
  padding: 30px;
  /* font-size: 25px; */
  font-weight: 400;
  z-index: 11;
  height: 200px;
  margin-top: 10px;
  margin-left: -330px;
  width: 550px;
  text-align: right;

}

.projectRightTitle {
  margin-top: 150px;
  font-size: 40px;
  line-height: .9;
  font-size: 50px;
}

.projectRightDesc {
  margin-top: 30px;
  font-size: 22px;
  width: 490px;
  line-height: .9;
  text-align: right;
}

.projectRightLiveBtn {
  margin-top: 50px;
  font-size: 22px;
}

.projectRightLiveBtnLine {
  width: 92px;
  height: 4px;
  background-color: #d53a9d;
  margin-left: 398px;
}

.projectRightLive:hover .projectRightLiveBtnLine{
  width: 5px;
  transition: all 2s ease;
}

.projectRightLearnMoreBtn {
  margin-top: 20px;
  font-size: 22px;
}

.projectRightLearnMoreBtnLine {
  width: 132px;
  height: 4px;
  background-color: #d53a9d;
  margin-left: 358px;
}

.projectRightLearnMore:hover .projectRightLearnMoreBtnLine {
  width: 5px;
  transition: all 2s ease;
}

@media screen and (max-width: 995px) {

  .projectLeft {
    width: 100vw;
    height: 400px;
    margin-bottom: 10px;
  }
  
  .projectLeftSection {
    z-index: 10;
    position: absolute;
    width: 650px;
    z-index: 8;
    height: 350px;
    border: 10px solid;
    border-image-slice: 1;
    border-width: 5px;
    /* background-color: #743ad5 ; */
    left:0px;
    background-image: linear-gradient(to left, #743ad5, #2E1755);
  }
  
  .projectPhotoLeft {
    height: 336px;
    width: 450px;
    position: absolute;
    left:-40px;
    /* margin-left: 400px; */
    margin-top: 25px;
    filter: grayscale(100);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  transition: all 1s ease;
  }
  
  .projectPhotoLeft:hover{
    transform: scale(.9);
    filter:grayscale(0)
  }
  
  p {
    margin-bottom: 0px;
  }
  
  .projectLeftText {
    color: var(--silk);
    padding: 30px;
    /* font-size: 25px; */
    font-weight: 400;
    z-index: 11;
    height: 200px;
    margin-left: 460px;
    width: 550px;
    margin-top: 55px;
  
  }
  
  .projectLeftTitle {
    margin-top: -10px;
    font-size: 40px;
    line-height: .9;
    font-size: 40px;
  }
  
  .projectLeftDesc {
    margin-top: 10px;
    font-size: 20px;
    width: 500px;
    line-height: .9;
  }
  
  .projectLeftLiveBtn {
    margin-top: 40px;
    font-size: 25px;
    /* margin-left: 50px; */
  }
  
  .projectLeftLiveBtnLine {
    width: 111px;
    height: 4px;
    background-color: #d53a9d;
    /* margin-left: 50px; */
    transition: all .15s ease;
  }
  
  /* .projectLeftLiveBtnLine:hover {
    width: 5px;
  } */
  
  
  .projectLeftLearnMoreBtn {
    margin-top: 10px;
    font-size: 25px;
    /* margin-left: 50px; */
  }
  
  .projectLeftLearnMoreBtnLine {
    width: 159px;
    height: 4px;
    background-color: #d53a9d;
    /* margin-left: 50px; */
    transition: all .15s ease;
  }
  
  /* .projectLeftLearnMoreBtnLine:hover {
    width: 5px;
  
  } */
  
  
  .projectRight {
    width: 100vw;
    height: 400px;
    margin-bottom: 10px;
    display: flex;
    justify-content: right;
  }
  
  
  .projectRightSection {
    z-index: 10;
    position: absolute;
    width: 650px;
    z-index: 8;
    height: 350px;
    border: 10px solid;
    border-image-slice: 1;
    border-width: 5px;
    /* background-color: #743ad5 ; */
    /* right: 0px; */
    background-image: linear-gradient(to left, #2E1755, #743ad5);
    /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
    /* transition: all 1s ease; */
    display: flex;
    justify-content: right;
  }
  
  .projectPhotoRight {
    height: 336px;
    width: 450px;
    position: absolute;
    margin-left: 80px;
    margin-top: 25px;
    filter: grayscale(100);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    transition: all 1s ease;
    margin-right: 10px;
  
  }
  
  .projectPhotoRight:hover{
    transform: scale(.9);
    filter:grayscale(0)
  }
  
  p {
    margin-bottom: 0px;
  }
  
  .projectRightText {
    color: var(--silk);
    padding: 30px;
    /* font-size: 25px; */
    font-weight: 400;
    z-index: 11;
    height: 200px;
    margin-top: 55px;
    margin-right: 455px;
    width: 550px;
    text-align: right;
  
  }
  
  .projectRightTitle {
    margin-top: -10px;
    font-size: 40px;
    line-height: .9;
    font-size: 40px;
  }
  
  .projectRightDesc {
    margin-top: 10px;
    font-size: 20px;
    width: 485px;
    line-height: .9;
    text-align: right;
    margin-left: 1px;
  }
  
  .projectRightLiveBtn {
    margin-top: 40px;
    font-size: 25px;
  
  }
  
  .projectRightLiveBtnLine {
    width: 111px;
    height: 4px;
    background-color: #d53a9d;
    margin-left: 378px;
    transition: all .15s ease;
  }
  
  /* .projectRightLiveBtnLine:hover {
    width: 5px;
    margin-left: 285px;
  } */
  
  .projectRightLearnMoreBtn {
    margin-top: 10px;
    font-size: 25px;
  
  }
  
  .projectRightLearnMoreBtnLine {
    width: 158px;
    height: 4px;
    background-color: #d53a9d;
    margin-left: 330px;
    transition: all .15s ease;
  }
  

}


@media screen and (max-width: 450px) {
  .workBody{
    height:650px;
  }

  .workSection {
    width: 100vw;
    display: flexbox;
    align-items: center;
    justify-content: center;
  }
  
  .workTitle{
    margin-bottom: 0px;
  }
  .workTitleText {
    margin-top: 35px;
    font-size: 30px;
    color: var(--silk);
    z-index: 9;
    height: 50px;
    text-align: center;
  }

  .workTitleBox {
    background-image: linear-gradient(to left, #743ad5, #d53a9d);
    position: absolute;
    height: 20px;
    width: 130px;
    margin-left: 40px;
    top: 60px;
    z-index: 8;
    border-radius: 1px;
  }


.projectLeft {
  width: 100vw;
  height: 200px;
  margin-bottom: 10px;
}

.projectLeftSection {
  z-index: 10;
  position: absolute;
  width: 270px;
  z-index: 8;
  height: 155px;
  border: 10px solid;
  border-image-slice: 1;
  border-width: 5px;
  /* background-color: #743ad5 ; */
  left:0px;
  background-image: linear-gradient(to left, #743ad5, #2E1755);
}

.projectPhotoLeft {
  height: 144px;
  width: 180px;
  position: absolute;
  left:-40px;
  /* margin-left: 400px; */
  margin-top: 15px;
  filter: grayscale(100);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
transition: all 1s ease;
}

.projectPhotoLeft:hover{
  transform: scale(.9);
  filter:grayscale(0)
}

p {
  margin-bottom: 0px;
}

.projectLeftText {
  color: var(--silk);
  padding: 30px;
  /* font-size: 25px; */
  font-weight: 400;
  z-index: 11;
  height: 200px;
  margin-left: 170px;
  width: 350px;
  margin-top:5px;
}

.projectLeftTitle {
  margin-top: -10px;
  font-size: 40px;
  line-height: .9;
  font-size: 18px;
}

.projectLeftDesc {
  margin-top: 10px;
  font-size: 10px;
  width: 150px;
  line-height: .9;
}

.projectLeftLiveBtn {
  margin-top: 5px;
  font-size: 14px;
  /* margin-left: 50px; */
}

.projectLeftLiveBtnLine {
  width: 63px;
  height: 4px;
  background-color: #d53a9d;
  /* margin-left: 50px; */
  transition: all 1s ease;
}

/* .projectLeftLiveBtnLine:hover {
  width: 5px;
} */


.projectLeftLearnMoreBtn {
  margin-top: 5px;
  font-size: 14px;
  /* margin-left: 50px; */
}

.projectLeftLearnMoreBtnLine {
  width: 90px;
  height: 4px;
  background-color: #d53a9d;
  /* margin-left: 50px; */
  transition: all 1s ease;
}

/* .projectLeftLearnMoreBtnLine:hover {
  width: 5px;

} */


.projectRight {
  width: 100vw;
  height: 350px;
  margin-bottom: 10px;
  display: flex;
  justify-content: right;
}


.projectRightSection {
  z-index: 10;
  position: absolute;
  width: 270px;
  z-index: 8;
  height: 159px;
  border: 10px solid;
  border-image-slice: 1;
  border-width: 5px;
  /* background-color: #743ad5 ; */
  /* right: 0px; */
  background-image: linear-gradient(to left, #2E1755, #743ad5);
  /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
  /* transition: all 1s ease; */
}

.projectPhotoRight {
  height: 144px;
  width: 180px;
  position: absolute;
  /* margin-left: 350px; */
  margin-top: 15px;
  filter: grayscale(100);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  transition: all 1s ease;
  right:1px;

}

.projectPhotoRight:hover{
  transform: scale(.9);
  filter:grayscale(0)
}

p {
  margin-bottom: 0px;
}

.projectRightText {
  color: var(--silk);
  padding: 30px;
  /* font-size: 25px; */
  font-weight: 400;
  z-index: 11;
  height: 200px;
  margin-top: -5px;
  /* margin-left: -250px; */
  width: 350px;
  text-align: right;
  margin-right: 455px;

}

.projectRightTitle {
  margin-top: -10px;
  width:170px;
  line-height: .9;
  font-size: 18px !important;
  margin-left: 405px;
}

.projectRightDesc {
  margin-top: 10px;
  font-size: 10px;
  width: 155px;
  line-height: .9;
  text-align: right;
  margin-left: 420px;
}

.projectRightLiveBtn {
  margin-top: 5px;
  font-size: 14px;
  margin-left: 425px;
  width: 150px;

}

.projectRightLiveBtnLine {
  width: 63px;
  height: 4px;
  background-color: #d53a9d;
  margin-left: 88px;
  transition: all 1s ease;
}

/* .projectRightLiveBtnLine:hover {
  width: 5px;
  margin-left: 285px;
} */

.projectRightLearnMoreBtn {
  margin-top: 5px;
  font-size: 14px;
  width: 150px;
  margin-left:425px;
}

.projectRightLearnMoreBtnLine {
  width: 90px;
  height: 4px;
  background-color: #d53a9d;
  margin-left: 61px;
  transition: all 1s ease;
}

/* .projectRightLearnMoreBtnLine:hover {
  width: 5px;
  margin-left: 285px;

} */

}